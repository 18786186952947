import MenuToggle from '../icons/MenuToggle';
import logo from '../assets/logo.svg';
import styles from '../styles/Header.module.css';
import { useRef } from 'react';

interface HeaderProps {
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Header({ setIsMenuOpen } : HeaderProps) {
    const toggleRef = useRef<HTMLButtonElement | null>(null);

    const handleToggle = () => {
        if (toggleRef.current) {
            // toggleRef.current.classList.toggle(styles.open);
        }
        // setIsMenuOpen((prev: boolean) => !prev);
    }

    return (
        <div className={styles.container}>
            <button ref={toggleRef} className={styles.toggleWrapper} onClick={handleToggle}>
                <MenuToggle/>
            </button>
            <p className={styles.year}>2024</p>
            <div className={styles.titleWrapper}>
                <p className={styles.title}>Big if True Science (BiTS) Accelerator</p>
                <div className={styles.mobileTitle} />
            </div>
            <img className={styles.logo} src={logo}/>
        </div>
    );
}