import { useState, lazy, useEffect } from "react";
import Grain from "./components/Grain";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Menu from "./components/Menu";
import styles from './styles/App.module.css'
import Background from "./components/Background";
import { Analytics } from "@vercel/analytics/react"
import PixelTransition from "./components/PixelTransition";
import bcrypt from 'bcryptjs';

const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [input, setInput] = useState('');
  const [status, setStatus] = useState('idle') // `success` `failure` `idle`
  const hashedPassword = process.env.REACT_APP_PASSWORD_HASH;

  const handleSubmit = () => {
    bcrypt.compare(input, hashedPassword, (err : any, result : any) => {
      if (result) {
        setStatus('success');
      } else {
        setStatus('failure');
      }
    });
  };

  useEffect(() => {
    if (status === 'success') {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }

  }, [status])

  return (
    status === 'success' ? (
    <>
      <Grain/>
      <Header setIsMenuOpen={setIsMenuOpen} />
      <Nav/>
      <Background/>
      <div className={styles.sections}>
        <Home/>
        <About/>
      </div>
      {/* <Menu isMenuOpen={isMenuOpen} />
      <PixelTransition isMenuOpen={isMenuOpen}/> */}
      <Analytics />
    </>
    ) : (
      <div className={styles.container}>
        <div className={styles.passwordContainer}>
          <p className={styles.title}>Big if True Science</p>
          <div className={styles.infoWrapper}>
            <img className={styles.lock} src='./lock.svg'/>
            <p className={styles.info}>Protected content</p>
          </div>
          <div className={status === 'failure' ? styles.inputContainerFailure : styles.inputContainer}>
            <input
              className={styles.passwordInput}
              type="password" 
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit() }}
              placeholder="Enter password"
              autoFocus
            />
            <button onClick={handleSubmit} className={styles.submit}>→</button>
          </div>
        </div>
      </div>
    )
  );
}

export default App;
