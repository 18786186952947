import type { SVGProps } from "react";

const MenuToggle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        {...props}
    >
        <rect x="8.17285" y="15.729" width="9.87617" height="9.87617" transform="rotate(-45 8.17285 15.729)" fill="black"/>
        <rect x="8.36328" y="30.4624" width="9.87617" height="9.87617" transform="rotate(-45 8.36328 30.4624)" fill="black"/>
        <rect x="23.4795" y="15.3467" width="9.87617" height="9.87617" transform="rotate(-45 23.4795 15.3467)" fill="black"/>
        <rect x="23.2881" y="30.8442" width="9.87617" height="9.87617" transform="rotate(-45 23.2881 30.8442)" fill="black"/>
    </svg>
);
export default MenuToggle;