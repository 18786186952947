import { memo, useEffect, useRef, useState } from 'react';
import styles from '../styles/Background.module.css'
import eye from '../assets/videos/eye.mp4';
import tree from '../assets/videos/tree.mp4';
import grass from '../assets/videos/grass.mp4';
import eyeImg from '../assets/images/eye.webp';
import eyeMain from '../assets/images/eye-main.png';
import treeImg from '../assets/images/tree.webp';
import grassImg from '../assets/images/grass.webp';
import blueLight from '../assets/images/blue-light.png';
import Video from './Video';

const Background = memo(function Background() {
  const blurRef = useRef(null);

  const handleScroll = (e: any) => {
    const height = window.innerHeight - 64 - 28
    const scrollPadding = 100
    const scrollInfluence = 0.04 // Controls acceleration
    const maxBlur = 4
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    
    // Adjust the blur intensity based on scroll position
    const blurIntensity = Math.max(0, Math.min((scrollTop - height + scrollPadding) * scrollInfluence, maxBlur));
    
    // Set the blur style
    if (blurRef.current) {
      // blurRef.current.style.backdropFilter = `blur(${blurIntensity}px)`;
    }
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [])

  return (
    <div className={styles.container}>
        {/* <div className={styles.topRightGradient}></div> */}
        <div className={styles.greenRectangle}></div>
        <Video vidSrc={eye} imgSrc={eyeImg} style={styles.eye} />
        <Video vidSrc={tree} imgSrc={treeImg} style={styles.tree} />
        {/* <Video vidSrc={grass} imgSrc={grassImg} style={styles.grassLeft} /> */}
        <Video vidSrc={grass} imgSrc={grassImg} style={styles.grassRight} />
        <img className={styles.eyeMain} src='../eye-main.png' />
        {/* <img className={styles.eyeOverlay} src='../eye-overlay.png' /> */}
        <img className={styles.blueLight} src={blueLight.src} />
        <div className={styles.black}></div>
        <div ref={blurRef} className={styles.blur}></div>
    </div>
  )
});

export default Background;