import styles from "../styles/Nav.module.css";
import ChevronForward from "../icons/ChevronForward";
import { useState } from 'react';
import { useScramble } from "use-scramble";
import { sectionNames } from "../utils/constants";

export default function Nav() {
    const [selectedSection, setSelectedSection] = useState(0);

    const { ref: homeRef, replay: homeReplay } = useScramble({ text: "HOME", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: aboutRef, replay: aboutReplay } = useScramble({ text: "ABOUT & HISTORY", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: teamRef, replay: teamReplay } = useScramble({ text: "TEAM & FELLOWS", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })
    const { ref: programRef, replay: programReplay } = useScramble({ text: "PROGRAMS", overdrive: false, speed: 1, tick: 1, step: 1, scramble: 2, seed: 0, chance: 1, range: [97, 122], overflow: false, playOnMount: false })

    const sectionRefs = [homeRef, aboutRef, teamRef, programRef]
    const sectionAnimations = [homeReplay, aboutReplay, teamReplay, programReplay]

    const handleGoto = (i: number) => {
        setSelectedSection(i);
        sectionAnimations[i]();
    }

    return (
        <div className={styles.container}>
            {/* {sectionNames.map((name, i) => 
                <div key={i} className={selectedSection === i ? styles.selectedSection : styles.section} onClick={_ => handleGoto(i)}>
                    <p ref={sectionRefs[i]} className={styles.title}>{name}</p>
                    <div className={styles.goto}>
                        <ChevronForward/>
                    </div>
                </div>
            )} */}
            <div className={styles.section}>

            </div>
        </div>
    )
}