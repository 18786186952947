import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from '../styles/Video.module.css';
import { StaticImageData } from 'next/image';

interface VideoProps {
  imgSrc: StaticImageData;
  vidSrc: string;
  style?: string;
}

const Video: React.FC<VideoProps> = ({ imgSrc, vidSrc, style }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className={style}>
      <video
        className={styles.video}
        loop
        autoPlay
        muted
        playsInline
        onPlay={() => setVideoLoaded(true)}
      >
        <source src={vidSrc} type="video/mp4" />
      </video>
      <AnimatePresence>
        {!videoLoaded && (
          <motion.img
            className={styles.video}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            src={imgSrc}
            alt="Video placeholder"
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Video;
